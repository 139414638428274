import type { Locales } from "remix-utils/locales/server";
import { getClientLocales } from "remix-utils/locales/server";

export const formatCurrency = (
  amount: number | null,
  currency: string,
  locales?: Locales,
) => {
  return new Intl.NumberFormat(locales ?? "en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
  }).format((amount || 0) / 100);
};

export function getClientLocale(request: Request) {
  const locales = getClientLocales(request);
  const locale = locales?.[0] ? locales[0] : "en";
  return locale.split("-")?.[0]?.toLowerCase();
}
